<template>
	<article class="lp" id="js_pagetop">
		<h1 class="lp__header"><img src="~@/assets/img/lp_temperaturedifference202210/title.png" alt="季節の変わり目は要注意！ 寒暖差疲労を予防しよう presented by 頭痛ーる"></h1>

		<!-- イントロ -->
		<p class="lp__intro">涼しく過ごしやすい気温の日が多くなる秋。<br>でも実は、日に日に気温が下がっていくこの時期は、<em>「寒暖差疲労」</em>に注意が必要なんです。</p>
		<!-- /イントロ -->

		<!-- ファーストビュー -->
		<section class="lp__first-view">
			<h2 class="lp__first-view--title"><em>寒暖差疲労</em>とは？</h2>
			<div class="lp__first-view--inner">
				<img src="~@/assets/img/lp_temperaturedifference202210/first-view_bg.png" alt="気温差（7℃以上）が大きい時に起こりやすく、体温を調節する自律神経が過剰に働いてしまう「気象病」の1つです。寒暖差疲労は以下のような場面で発生する寒暖差の際に起こりやすいです。症状：全身倦怠感/冷え性/頭痛/首こり・肩こり/胃腸障害/イライラ/不安/アレルギー（鼻炎症状）など。春の暖かくなっていく時期の寒暖差。夏の暑い時期の寒暖差。秋の寒くなっていく時期の寒暖差。室内外（エアコン使用時と外気の気温差が大きい場合）の寒暖差。前日比の寒暖差。">
				<p class="lp__first-view--text">今回は、寒暖差疲労の原因と手軽にできる対策ご紹介します!</p>
			</div>
		</section>
		<!-- /ファーストビュー -->

		<!-- 目次 -->
		<nav class="lp__nav">
			<ul class="lp__nav--list">
				<li class="lp__nav--item is-step1" title="STEP1"><a href="javascript:void(0);" @click="onScroll('js_step1')"><span>1分で診断!<br>寒暖差疲労<br>チェック</span></a></li>
				<li class="lp__nav--item is-step2" title="STEP2"><a href="javascript:void(0);" @click="onScroll('js_step2')"><span>寒暖差指数<br>を毎日<br>見よう!</span></a></li>
				<li class="lp__nav--item is-step3" title="STEP3"><a href="javascript:void(0);" @click="onScroll('js_step3')"><span>絵でわかる<br>寒暖差疲労<br>5つの対策</span></a></li>
			</ul>
		</nav>
		<!-- /目次 -->

		<!-- STEP1 -->
		<section class="lp__contents is-step1" id="js_step1">
			<h2 class="lp__contents--title"><span>寒暖差疲労セルフチェックで<br><em>自分の傾向を知ろう！</em></span></h2>
			<div class="lp__contents--inner">
				<p class="lp__contents--text">あなたの身体は寒暖差に強い？&emsp;弱い？<br>1分でわかるセルフチェックをやってみてくださいね。</p>
				<h3 class="lp__contents--check">▼タップしてCHECK！▼</h3>
				<a class="lp__contents--link gtm-link_click_count01" data-gtm-event-cat-label="寒暖差特集ページ202210リンク2" data-gtm-event-act-label="trackingLpTemperatureDifference202210Btn02" href="javascript: void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=selfcheckTemperatureDifference` } })"><img src="@/assets/img/lp_temperaturedifference202210/bnr01.png" alt="医師監修 寒暖差疲労セルフチェック 今すぐチェック"></a>
				<p class="lp__contents--supervisor">監修:せたがや内科・神経内科クリニック<br>久手堅 司先生</p>
			</div>
		</section>
		<!-- /STEP1 -->

		<!-- STEP2 -->
		<section class="lp__contents is-step2" id="js_step2">
			<h2 class="lp__contents--title"><span>健康天気指数に登場した<br><em>寒暖差指数<span>を</span>毎日見よう!</em></span></h2>
			<div class="lp__contents--inner">
				<p class="lp__contents--text">便利な健康天気指数に、新しく<em>「寒暖差指数」</em>が登場しました！<span>(協力:久手堅司先生)</span></p>
				<h3 class="lp__contents--check">▼タップしてCHECK！▼</h3>
				<a class="lp__contents--link" href="javascript: void(0);" @click="clickModalLink(1, $event)"><img src="@/assets/img/lp_temperaturedifference202210/swiper1_1.png" alt="寒暖差指数とは？"></a>
				<p class="lp__contents--comment"><span>寒暖差指数のチェックを毎朝の習慣にしてみてニャ！</span></p>
				<a class="lp__contents--button gtm-link_click_count01" data-gtm-event-cat-label="寒暖差特集ページ202210リンク1" data-gtm-event-act-label="trackingLpTemperatureDifference202210Btn01" href="javascript: void(0);" @click="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })">今日の健康天気指数を見てみる</a>
			</div>
		</section>
		<!-- /STEP2 -->

		<!-- STEP3 -->
		<section class="lp__contents is-step3" id="js_step3">
			<h2 class="lp__contents--title"><span>手軽にできる寒暖差疲労対策を<br><em>やってみよう！</em></span></h2>
			<div class="lp__contents--inner">
				<p class="lp__contents--text">寒暖差疲労を予防するための生活のちょっとしたコツをご紹介します。<br>できるところから試してみて下さいね！</p>
				<h3 class="lp__contents--check">▼タップしてCHECK！▼</h3>
				<a class="lp__contents--link gtm-link_click_count01" data-gtm-event-cat-label="寒暖差特集ページ202210リンク3" data-gtm-event-act-label="trackingLpTemperatureDifference202210Btn03" href="javascript: void(0);" @click="clickModalLink(2, $event)"><img src="@/assets/img/lp_temperaturedifference202210/swiper2_1.png" alt="頭痛ーる 寒暖差疲労 5つの対策"></a>
			</div>
		</section>
		<!-- /STEP3 -->

		<!-- スタンププレゼント -->
		<section class="lp__stamp" id="js_stamp">
			<div class="lp__stamp--inner">
				<p class="lp__stamp--comment is-hiroshi"><span>特集を最後まで見てくれたあなたにプレゼントじゃ！</span></p>
				<p class="lp__stamp--comment is-maro"><span>秋を楽しくするスタンプだよ～。使ってみてニャ！</span></p>
				<h2 class="lp__stamp--title"><em>＼スゴ得限定／</em><img src="@/assets/img/lp_temperaturedifference202210/subtitle04.png" alt="ハロウィンスタンププレゼント"></h2>
				<p class="lp__stamp--img"><img src="@/assets/img/lp_temperaturedifference202210/stamp.png" alt="Happy Halloween 2022 時は来た！ おひとつどうぞ イイネ！"></p>
				<a class="lp__stamp--button gtm-link_click_count01" data-gtm-event-cat-label="寒暖差特集ページ202210リンク4" data-gtm-event-act-label="trackingLpTemperatureDifference202210Btn04" href="javascript: void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=stamp`, _stampType: 'halloween', _backPage: 'top' } })">プレゼントをもらう</a>
			</div>
		</section>
		<!-- /スタンププレゼント -->

		<!-- アウトロ -->
		<section class="lp__outro">
			<h2 class="lp__outro--title">with頭痛ーるで<br>寒暖差に負けない秋に！</h2>
			<div class="lp__outro--img"><img src="@/assets/img/lp_temperaturedifference202210/character.png" alt="マロ ヒロシ テルネコ"></div>
			<a href="javascript:void(0);" @click="onScroll('js_pagetop')" class="lp__outro--button is-pagetop">上部にもどる</a>
			<a href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })" class="lp__outro--button is-back">TOPにもどる</a>
		</section>
		<!-- /アウトロ -->

		<!-- 下に吸着するアプリダウンロードボタン -->
		<aside class="sticky-bottom-appdownload">
			<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="sticky-bottom-appdownload--button gtm-link_click_count01" data-gtm-event-cat-label="寒暖差特集ページ202210リンク5Android" data-gtm-event-act-label="trackingLpTemperatureDifference202210Btn05_android"><img src="~@/assets/img/sugotoku_app_info_btn03.png" alt="今すぐダウンロード！"></a>
			<a v-else href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="sticky-bottom-appdownload--button gtm-link_click_count01" data-gtm-event-cat-label="寒暖差特集ページ202210リンク5iOS" data-gtm-event-act-label="trackingLpTemperatureDifference202210Btn05_ios"><img src="~@/assets/img/sugotoku_app_info_btn03.png" alt="今すぐダウンロード！"></a>
		</aside>
		<!-- /下に吸着するアプリダウンロードボタン -->

		<!-- モーダル -->
		<transition name="fade">
			<!-- NOTE: 画像の多いモーダルをv-ifで初回レンダリングしないことで、ページ読み込みを早くする。 -->
			<aside v-if="modalType !== 0" class="lp__modal">
				<div class="lp__modal--inner">
					<!-- slider -->
					<div class="lp__slider">
						<div class="lp__slider--container swiper-container js_swiper">
							<!-- 寒暖差指数 -->
							<ul v-if="modalType === 1" class="swiper-wrapper">
								<li class="swiper-slide">
									<img src="@/assets/img/lp_temperaturedifference202210/swiper1_1.png" alt="寒暖差指数とは？">
								</li>
								<li class="swiper-slide">
									<img src="@/assets/img/lp_temperaturedifference202210/swiper1_2.png" alt="寒暖差指数は、頭痛ーるユーザー約100万人のデータを基に、気象予報士が開発した頭痛ーるオリジナルの指数です。">
								</li>
								<li class="swiper-slide">
									<img src="@/assets/img/lp_temperaturedifference202210/swiper1_3.png" alt="前日との気温差をベースに、寒暖差によって起こる体調への影響の大きさを5段階でお知らせします。">
								</li>
								<li class="swiper-slide">
									<img src="@/assets/img/lp_temperaturedifference202210/swiper1_4.png" alt="季節の変わり目には、急激に気温が下がることもありますから、寒暖差指数を毎朝チェックしてみてください。">
								</li>
								<li class="swiper-slide">
									<img src="@/assets/img/lp_temperaturedifference202210/swiper1_5.png" alt="寒暖差の影響が心配な日は「早めに休む」「薬を準備しておく」など、寒暖差疲労の予防にお役立てくださいね！">
								</li>
							</ul>
							<!-- /寒暖差指数 -->
							<!-- 寒暖差疲労 -->
							<ul v-else class="swiper-wrapper">
								<li class="swiper-slide">
									<img src="@/assets/img/lp_temperaturedifference202210/swiper2_1.png" alt="寒暖差疲労と5つの対策">
								</li>
								<li class="swiper-slide">
									<img src="@/assets/img/lp_temperaturedifference202210/swiper2_2.png" alt="&#9312;体を中から温める">
								</li>
								<li class="swiper-slide">
									<img src="@/assets/img/lp_temperaturedifference202210/swiper2_3.png" alt="&#9313;体を外から温める">
								</li>
								<li class="swiper-slide">
									<img src="@/assets/img/lp_temperaturedifference202210/swiper2_4.png" alt="&#9314;体を軽く動かす">
								</li>
								<li class="swiper-slide">
									<img src="@/assets/img/lp_temperaturedifference202210/swiper2_5.png" alt="&#9315;ゆっくりと深い呼吸を行う">
								</li>
								<li class="swiper-slide">
									<img src="@/assets/img/lp_temperaturedifference202210/swiper2_6.png" alt="&#9316;腸内環境を整える">
								</li>
								<li class="swiper-slide">
									<img src="@/assets/img/lp_temperaturedifference202210/swiper2_7.png" alt="昨今はステイホーム習慣やテレワークの普及による運動不足などで、自律神経を調整する力が低下している恐れがあります。そのため例年以上に身体が寒暖差に弱くなっている可能性があります。寒暖差疲労の予防や対策として紹介した5つの方法を是非お役立てください！">
								</li>
							</ul>
							<!-- /寒暖差疲労 -->
						</div>
						<div class="lp__slider--nav">
							<div class="swiper-button-prev js_swiper_prev"></div>
							<div class="swiper-button-next js_swiper_next"></div>
						</div>
						<div class="swiper-pagination-bullets js_swiper_pagination"></div>
					</div>
					<!-- /slider -->
					<div class="lp__modal--button">
						<a href="javascript: void(0);" @click="modalType = 0">
							<svg xmlns="http://www.w3.org/2000/svg" width="96" height="25" viewBox="0 0 96 25">
								<g transform="translate(-152.762 -389.5)">
									<text transform="translate(170.762 407.5)" fill="#333" font-size="13" font-family="rounded-mplus-1c-regular, 'Rounded M\+ \31 c'"><tspan x="0" y="0">表示を閉じる</tspan></text>
									<text data-name="×" transform="translate(152.762 408.5)" fill="#333" font-size="18" font-family="rounded-mplus-1c-regular, 'Rounded M\+ \31 c'"><tspan x="0" y="0">×</tspan></text>
								</g>
							</svg>
						</a>
					</div>
				</div>
			</aside>
		</transition>
		<!-- /モーダル -->
	</article>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// ライブラリ
import cmnConst from '@/assets/js/constant.js'
import Swiper from 'swiper'

export default {
	name: 'LpTemperatureDifference202210',
	components: {
	},
	data () {
		return {
			backendUrl: cmnConst.BACKEND_URL,
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID,
			modalType: 0,
			$html: null
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['isAndroid'])
	},
	created () {
	},
	mounted () {
		// 横幅に合わせてフォントサイズや余白をレスポンシブに可変する対応
		// TODO: Webview、スゴ得でリポジトリをわける際、common.scssでルートフォントをvwで指定するようにしたい。もしできたらこちらの処理は不要なので削除。
		const propWidth = document.body.offsetWidth
		const style = propWidth <= 640 ? 'font-size: 16px; font-size: 4.267vw;' : 'font-size: 27px;'
		this.$html = document.getElementsByTagName('html')[0]
		this.$html.setAttribute('style', style)

		// ページ遷移後のアンカーリンク
		// NOTE: 今後統一する予定
		if (this.$route.hash) window.addEventListener('load', this.hashAnchorLink)
	},
	beforeDestroy () {
		window.removeEventListener('load', this.hashAnchorLink)
		this.$html.removeAttribute('style')
	},
	methods: {
		// ページスクロール用の関数
		onScroll (id) {
			const elem = document.getElementById(id)
			const options = {
				container: 'body',
				duration: 500,
				easing: 'ease-out',
				offset: 0,
				cancelable: false,
				onStart: false,
				onDone: false,
				onCancel: false,
				x: false,
				y: true
			}
			this.$scrollTo(elem, options)
		},

		// モーダルのリンククリックイベント
		clickModalLink (value, event) {
			event.preventDefault()
			this.modalType = value

			// NOTE: 画像の多いモーダルをv-ifで初回レンダリングしないことで、ページ読み込みを早くするため、Swiperを都度作り直すようにした。
			this.$nextTick(() => {
				this.onSwiper()
			})
		},

		// Swiperの処理
		onSwiper () {
			const swiperConstructor = () => {
				return new Swiper('.js_swiper', {
					slidesPerView: 1,
					spaceBetween: 10,
					observer: true,
					observeParents: true,
					navigation: {
						nextEl: '.js_swiper_next',
						prevEl: '.js_swiper_prev'
					},
					pagination: {
						el: '.js_swiper_pagination'
					}
				})
			}
			swiperConstructor()
		},

		// ページ遷移後のアンカーリンク
		// NOTE: 今後統一する予定
		hashAnchorLink () {
			const $elem = document.querySelector(this.$route.hash)
			if ($elem) $elem.scrollIntoView ? $elem.scrollIntoView({ behavior: 'smooth', block: 'start' }) : scrollTo(0, $elem.getBoundingClientRect().top)
		}
	}
}
</script>

<style lang="scss" scoped>
@import "~@/assets/sass/variable";
@import "~swiper/css/swiper.min.css";

// NOTE: LPページ限定の変数（デザインシステム外のため他での利用禁止）
$family-meiryo: 'メイリオ', 'Meiryo', sans-serif;
$lp-backgroud-color: #FFF7E2;
$lp-text-bold: #F82752;
$lp-text-grey: #707070;
$lp-border-color: #F8A12A;
$lp-button-color: #C63533;
$lp-button-border-color: #930D0B;
$lp-stamp-backgroud-color: #620077;
$spacing-12: 0.75rem;
$spacing-24: 1.5rem;
$spacing-25: 1.562rem;
$spacing-35: 2.187rem;
$line-height-1_375: 1.375;

.lp {
	font-family: $lp-family-primary;
	max-width: 640px;
	margin: 0 auto;
	background-color: $lp-backgroud-color;
	line-height: $line-height;
	color: $text-primary;
	font-size: $font-size-16;

	ul, li {
		list-style: none;
	}

	em { font-style: normal; }

	img {
		width: 100%;
		display: block;
	}

	&__header {
		margin: 0 auto;
		padding: $spacing-10 $spacing-14 0;
	}

	&__intro {
		margin: $spacing-10 auto 0;
		padding: 0 $spacing-14;

		& > em {
			font-family: $lp-family-tertiary;
			color: $lp-text-bold;
		}
	}

	&__first-view {
		position: relative;
		margin: $spacing-20 auto 0;

		&--title {
			position: absolute;
			width: 57.3334%;
			margin: $spacing-35 auto 0;
			padding: $spacing-8 0;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			text-align: center;
			color: transparent;
		}

		&--text {
			margin: $spacing-10 auto 0;
			padding: 0 $spacing-14;
		}
	}

	&__nav {
		margin: $spacing-24 auto 0;
		padding: 0 $spacing-14;

		&--list {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 0 auto;
			padding: 0;
		}

		&--item {
			position: relative;
			width: 31.124%;
			text-align: center;

			& > a {
				font-family: $family-primary;
				display: block;
				padding-top: 138.8889%;
				text-decoration: none;
				color: $text-seconday;
				line-height: $line-height-1_375;

				& > span {
					display: block;
					width: 100%;
					position: absolute;
					top: calc(50% + #{$spacing-12});
					left: 0;
					transform: translateY(-50%);
				}
			}

			@for $i from 1 through 3 {
				&.is-step#{$i} a {
					background: url(~@/assets/img/lp_temperaturedifference202210/nav0#{$i}.png) no-repeat 0 100%;
					background-size: 100% auto;
				}
			}
		}
	}

	&__contents {
		margin: $spacing-20 $spacing-14 0;

		&.is-step1 { margin: $spacing-18 $spacing-14 0; }

		&--title {
			position: relative;
			padding-top: 34.6821%;
			margin: 0;
			font-family: $lp-family-tertiary;
			text-align: center;
			font-size: $font-size-18;
			color: $text-seconday;

			& > span {
				display: block;
				width: 100%;
				position: absolute;
				bottom: $spacing-6;
				left: 0;

				& > em {
					font-size: $font-size-24;

					& > span { font-size: $font-size-20; }
				}
			}
		}

		@for $i from 1 through 3 {
			&.is-step#{$i} .lp__contents--title {
				background: url(~@/assets/img/lp_temperaturedifference202210/subtitle0#{$i}.png) no-repeat 0 100%;
				background-size: 100% auto;
			}
		}

		&--inner {
			padding: $spacing-8 $spacing-18 $spacing-20 $spacing-20;
			background-color: $background-primary;
			border: 2px solid $lp-border-color;
			border-top: none;
			border-radius: 0 0 10px 10px;
		}

		&.is-step2 .lp__contents--inner { padding: $spacing-10 $spacing-18 $spacing-18 $spacing-18; }
		&.is-step3 .lp__contents--inner { padding: $spacing-8 $spacing-18 $spacing-18 $spacing-20; }

		&--text {
			margin: 0;

			& > em { font-family: $lp-family-tertiary; }
			& > span { font-size: $font-size-12; }
		}

		&--check {
			margin: $spacing-10 auto 0;
			text-align: center;
			color: $lp-text-bold;
			font-size: $font-size-16;
		}

		&--link {
			display: block;
			width: 98.3607%;
			margin: $spacing-10 auto 0;
		}

		&.is-step1 .lp__contents--link { width: 100%; }

		&--comment {
			position: relative;
			padding-top: 20.847%;
			margin: $spacing-20 0 0 0;
			background: url(~@/assets/img/lp_temperaturedifference202210/maro.png) no-repeat 0 100%;
			background-size: 100% auto;

			& > span {
				position: absolute;
				display: block;
				width: 65.1466%;
				top: $spacing-10;
				left: $spacing-10;
				font-size: $font-size-14;
			}
		}

		&--button {
			display: block;
			width: 96.7214%;
			margin: $spacing-20 auto 0;
			padding: $spacing-10 0;
			border-radius: 6px;
			font-family: $family-primary;
			color: $text-seconday;
			text-align: center;
			text-decoration: none;
			background-color: $lp-button-color;
			font-size: $font-size-18;
			box-shadow: 0 3px 0 0 $lp-button-border-color;
		}

		&--supervisor {
			font-family: $family-meiryo;
			margin: $spacing-10 0 0 0;
			text-align: right;
			font-size: $font-size-10;
			color: $lp-text-grey;
		}
	}

	&__stamp {
		padding: 21.2766% 0 29.2554%;
		margin: $spacing-20 0 0 0;
		background: url(~@/assets/img/lp_temperaturedifference202210/bg01_top.png) no-repeat 0 0, url(~@/assets/img/lp_temperaturedifference202210/bg01_bottom.png) no-repeat 0 100%;
		background-size: 100% auto, 100% auto;

		&--inner {
			padding: $spacing-10;
			background-color: $lp-stamp-backgroud-color;
		}

		&--comment {
			position: relative;

			& > span {
				position: absolute;
				display: block;
				width: 65.1466%;
				font-size: $font-size-14;
			}

			&.is-hiroshi {
				padding-top: 23.0986%;
				margin: 0 $spacing-25 0 $spacing-26;
				background: url(~@/assets/img/lp_temperaturedifference202210/hiroshi.png) no-repeat 0 100%;
				background-size: 100% auto;

				& > span {
					top: $spacing-18;
					right: $spacing-10;
				}
			}

			&.is-maro {
				padding-top: 17.8874%;
				margin: $spacing-10 $spacing-24 0;
				background: url(~@/assets/img/lp_temperaturedifference202210/maro.png) no-repeat 0 100%;
				background-size: 100% auto;

				& > span {
					top: $spacing-8;
					left: $spacing-10;
				}
			}
		}

		&--title {
			position: relative;
			margin: $spacing-20 0 0 0;

			& > em {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				font-family: $lp-family-tertiary;
				text-align: center;
				color: $text-seconday;
				font-size: $font-size-16;
				font-weight: normal;
			}
		}

		&--img { margin: $spacing-20 $spacing-24 0; }

		&--button {
			display: block;
			width: 83.0986%;
			margin: $spacing-20 auto 0;
			padding: $spacing-10 0;
			border-radius: 6px;
			font-family: $family-primary;
			color: $text-seconday;
			text-align: center;
			text-decoration: none;
			background-color: $lp-button-color;
			font-size: $font-size-18;
			box-shadow: 0 3px 0 0 $lp-button-border-color;
		}
	}

	&__outro {
		margin: $spacing-20 0 0 0;

		&--title {
			margin: 0;
			font-family: $family-primary;
			text-align: center;
			font-weight: normal;
		}

		&--img {
			width: 66.6667%;
			margin: $spacing-20 auto 0;
		}

		&--button {
			font-family: $lp-family-secondary;
			display: block;
			width: 78.6667%;
			border-radius: 100px;
			font-weight: normal;
			text-align: center;
			text-decoration: none;
			box-sizing: border-box;

			&.is-pagetop {
				margin: $spacing-20 auto 0;
				padding: $spacing-6 0;
				border: 3px solid $primary;
				color: $primary;
				font-size: $font-size-18;
				background-color: $background-primary;
			}

			&.is-back {
				margin: $spacing-14 auto 0;
				padding: $spacing-10 0;
				color: $text-seconday;
				font-size: $font-size-24;
				background-color: $primary;
			}
		}
	}

	// ページ下部に吸着するボタン
	.sticky-bottom-appdownload {
		margin: $spacing-30 0 0 0;
		padding: $spacing-8 $spacing-14;
		box-shadow: 0px -2px 2px rgba(0,0,0,0.1);
	}

	// modal
	&__modal {
		position: fixed;
		height: 100vh;
		width: 100%;
		top: 0;
		left: 0;
		padding-top: 10vh;
		box-sizing: border-box;
		color: $text-primary;
		background-color: rgba($_black, .8);

		&--inner {
			position: relative;
			margin: 0 auto;
			width: 92.5334%;
			max-width: 592px;
			background-color: $background-primary;
		}

		&--button {
			width: 100%;
			padding: $spacing-6 0;
			background: $background-secondary;
			line-height: $line-height-1;
			text-align: center;

			& > a { text-decoration: none; }
		}
	}

	// swiper
	.swiper {
		&-container { margin: 0; }

		&-wrapper {
			margin: 0;
			padding: 0;
		}

		&-slide {
			padding: $spacing-20 $spacing-20 0;
			box-sizing: border-box;
		}

		&-button-prev,
		&-button-next {
			$swiper-nav-width: 40px;

			margin-top: -$swiper-nav-width;
			width: $swiper-nav-width;
			height: $swiper-nav-width;

			&:before,
			&:after { content: none; }

			&.swiper-button-disabled { opacity: 0; }
		}

		&-button-prev {
			left: $spacing-10;
			background: url(~@/assets/img/lp_temperaturedifference202210/slider_prev.png) center center / 100% auto no-repeat;
		}

		&-button-next {
			right: $spacing-10;
			background: url(~@/assets/img/lp_temperaturedifference202210/slider_next.png) center center / 100% auto no-repeat;
		}

		&-pagination-bullets {
			padding: $spacing-14 0;
			display: flex;
			align-items: center;
			justify-content: center;

			/deep/ .swiper-pagination-bullet {
				$swiper-bullets-width: 5px;

				margin: 0 $spacing-10;
				width: $swiper-bullets-width;
				height: $swiper-bullets-width;
				background: $button-secondary;
				opacity: 1;
			}

			/deep/ .swiper-pagination-bullet-active { background: $text-primary; }
		}
	}

	// トランジション
	.fade {
		&-enter-active,
		&-leave-active {
			transition: opacity .2s;
		}

		&-enter,
		&-leave-to {
			opacity: 0;
		}
	}
}
</style>
